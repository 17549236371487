import "./styles/App.scss";
import { Tabs } from "antd";
import SignUp from "./components/Signup";
import SignIn from "./components/Signin";
import BluenavLogo from "./assets/logo-bluenav.png";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslator } from "@eo-locale/react";

const App: () => JSX.Element = () => {
    const translator = useTranslator();
    const [searchParams, setSearchParams] = useSearchParams();
    const [codeGW, setCodeGW] = useState<string | null>('');

    useEffect(() => {
        setCodeGW(searchParams.get("code_gw"));
    }, [searchParams]);

    return (
        <div className="cognito-container">
            <div className="flex-container">
                <div className="form-container">
                    <div className="logo-container">
                        <img src={BluenavLogo} alt="bluenav-logo"></img>
                    </div>
                    <Tabs
                        defaultActiveKey="1"
                        centered
                        items={[
                            { label: translator.translate('Registration'), 
                                key: "1", 
                                children: <SignUp code_gw={codeGW}></SignUp> 
                            },
                            { label: translator.translate('Login'), 
                                key: "2", 
                                children: <SignIn></SignIn> 
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default App;
