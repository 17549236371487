import { CognitoUser } from "amazon-cognito-identity-js";
import { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { AccountContext } from "./AccountContext";
import "../styles/Confirm.scss";
import OtpInput from "react18-input-otp";
import BluenavLogo from "../assets/logo-bluenav.png";
import { Spin } from "antd";
import ActivationIMX8 from "./ActivationIMX8";
import { useTranslator } from "@eo-locale/react";

const Confirmation = () => {
    const translator = useTranslator();
    const { register, authenticate } = useContext(AccountContext);
    const [user, setUser] = useState<CognitoUser>();
    const location = useLocation();
    const [otpCode, setOtpCode] = useState<string>("");
    const [isValid, setIsValid] = useState(true);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);

    const [errorCodeSend, setErrorCodeSend] = useState(false);
    const [successCodeSend, setSuccessCodeSend] = useState(false);

    useEffect(() => {
        if (location?.state?.email) {
            setUser(register(location.state.email));
        }

        if (location?.state?.auth) {
            setIsConfirmed(true);
        }
    }, []);

    useEffect(() => {
        if (location?.state?.resend) {
            resendCode();
        }
    }, [user]);

    const displayError = () => {
        setErrorCodeSend(true);

        setTimeout(() => {
            setErrorCodeSend(false);
        }, 6000);
    };

    const displaySuccess = () => {
        setSuccessCodeSend(true);

        setTimeout(() => {
            setSuccessCodeSend(false);
        }, 6000);
    };

    const resendCode = () => {
        if (user) {
            user.resendConfirmationCode((err, res) => {
                if (err) {
                    displayError();
                    return;
                }
                displaySuccess();
                return;
            });
        }
    };

    const handleChange = (code: string) => {
        setOtpCode(code);
        if (code.length === 6) {
            if (user) {
                setOtpLoading(true);
                user.confirmRegistration(code, true, async (err, res) => {
                    if (err) {
                        setOtpLoading(false);
                        setIsValid(false);
                    }
                    if (res == "SUCCESS") {
                        setOtpLoading(false);
                        setIsValid(true);
                        await authenticate(location?.state?.email, location?.state?.password);
                        setIsConfirmed(true);
                    }
                });
            }
        } else {
            setIsValid(true);
        }
    };

    return (
        <div className="confirmation">
            <div className="confirmation-container">
                {isConfirmed ? (
                    <ActivationIMX8></ActivationIMX8>
                ) : (
                    <>
                        <img src={BluenavLogo} alt="bluenav-logo"></img>
                        <div className="confirm-message">
                            <p>{translator.translate("Confirm your email address")}</p>
                        </div>
                        <div className="confirm-code">
                            <p>
                                {translator.translate("Code sent to")}{" "}
                                <span className="email">{location?.state?.email}</span>
                            </p>
                        </div>
                        <div className="otp-container">
                            <OtpInput
                                inputStyle={"otp-cognito"}
                                isInputNum={true}
                                value={otpCode}
                                onChange={(v: string) => handleChange(v)}
                                numInputs={6}
                                separator={<span>-</span>}
                            />
                        </div>
                        {otpLoading && <Spin></Spin>}
                        {!isValid && (
                            <div className="validation-message">
                                <p>{translator.translate("The code is invalid")}</p>
                            </div>
                        )}
                        <div className="resend-message">
                            <p>
                                {translator.translate("You didn't receive the code")} ?{" "}
                                <span className="resend-btn" onClick={() => resendCode()}>
                                    {translator.translate("Resend the code")}
                                </span>
                            </p>
                        </div>
                        {errorCodeSend && (
                            <div className="resend-message">
                                <p className="error">
                                    {translator.translate(
                                        "Unable to return the code for the moment, please try again later"
                                    )}
                                </p>
                            </div>
                        )}
                        {successCodeSend && (
                            <div className="resend-message">
                                <p className="success">{translator.translate("Confirmation code sent")}</p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Confirmation;
