import { AccountContext } from "./AccountContext";
import { useContext, useEffect, useState } from "react";
import { useTranslator } from "@eo-locale/react";
import { Spin } from "antd";
import axios from "axios";

let URL_API = "https://register.bluenav.com/api";

if (process.env.NODE_ENV !== "production") {
    URL_API = "http://localhost:3001/api";
}

const ActivationIMX8 = () => {
    const translator = useTranslator();
    const { getSession } = useContext(AccountContext);
    const [loading, setLoading] = useState(true);
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [connected, setConnected] = useState<boolean>(false);

    useEffect(() => {
        getSession().then(async (data: any) => {
            const config = {
                headers: {
                    Authorization: data.accessToken.jwtToken,
                    clientid: data.user.pool.clientId,
                },
            };
            let res_code = await axios.get(`${URL_API}/verification_code`, config);

            setVerificationCode(res_code.data.verification_code);

            let res_imx8_status = await axios.get(`${URL_API}/imx8_status`, config);

            setConnected(res_imx8_status.data.status);

            setTimeout(() => {
                setLoading(false);
            }, 2000);
        });
    }, []);

    return (
        <>
            <div className="wrapper">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </div>
            <div className="validation-message-success">
                <p>{translator.translate("You can now use your BlueNav product")}</p>
            </div>
            <div className="imx8-instruct">
                {loading ? (
                    <Spin tip={translator.translate("Checking the device")}></Spin>
                ) : (
                    <>
                        {connected ? (
                            <>
                                <div>
                                    {translator.translate("Your device is")}{" "}
                                    <span className="success">{translator.translate("connected")}</span>.{" "}
                                    {translator.translate(
                                        "The activation page on your MFD will disappear in a few seconds"
                                    )}
                                </div>
                                <div className="manual">
                                    {translator.translate(
                                        'If not, please click on the button "I activate my product \
manually" and enter the following code:'
                                    )}{" "}
                                    <br></br>
                                    <span className="verificationCode">{verificationCode}</span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    {translator.translate("Your device is")}{" "}
                                    <span className="error">{translator.translate("disconnected")}</span>{" "}
                                    {translator.translate("from the internet for the moment but it can work normally")}
                                </div>
                                <div className="manual">
                                    {translator.translate(
                                        'Please click on the button "I activate my product manually" and \
enter the following code:'
                                    )}{" "}
                                    <br></br> <span className="verificationCode">{verificationCode}</span>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default ActivationIMX8;
