const supportedLang = ['en', 'fr'];

export const getLangFromUrl = (): string | undefined => {
    const search = window.location.search;
    const lang = new URLSearchParams(search).get('lang') || undefined;
    return lang;
};

export const getLangFromBrowser = (): string | undefined => {
    const language = window.navigator?.language || undefined;
    return (language || '').split('-', 1)[0];
};

export const decideLanguage = (): string => {
    const urlParam = getLangFromUrl();
    if (urlParam && supportedLang.includes(urlParam)) return urlParam;
    const browserLang = getLangFromBrowser();
    if (browserLang && supportedLang.includes(browserLang)) return browserLang;
    return 'en';
};