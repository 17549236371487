import { useState, useContext, useEffect } from "react";
import UserPool from "../utils/userPool";
import { Button, Checkbox, Form, Input } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { AccountContext } from "./AccountContext";
import { Navigate } from "react-router-dom";
import "../styles/Signup.scss";
import { useTranslator } from "@eo-locale/react";
import { isValidPhoneNumber } from "libphonenumber-js";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 7,
        },
    },
};

const cognitoAttributes = ["given_name", "family_name", "phone_number", "custom:boat_name", "custom:code_gw"];

const SignUp: ({ code_gw }: { code_gw: string | null }) => JSX.Element = ({ code_gw }) => {
    const translator = useTranslator();
    const [form] = Form.useForm();
    const [confirm, setConfirm] = useState<boolean>(false);
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const { authenticate } = useContext(AccountContext);

    useEffect(() => {
        form.setFieldValue("custom:code_gw", code_gw);
    }, [code_gw]);

    const onFinish = (values: any) => {
        setLoading(true);
        const attributeList = [];

        values.phone_number = `+${values.phone_number}`;
        values["custom:code_gw"] = values["custom:code_gw"].toLowerCase();

        for (let attribute of cognitoAttributes) {
            attributeList.push(new CognitoUserAttribute({ Name: attribute, Value: values[attribute] }));
        }

        UserPool.signUp(values.email, values.password, attributeList, [], (err, data) => {
            if (err) {
                if (err.name === "UsernameExistsException") {
                    form.setFields([
                        {
                            name: "email",
                            errors: [`${translator.translate("This e-mail address is already in use")}`],
                        },
                    ]);
                } else if (err.name === "UserLambdaValidationException") {
                    form.setFields([
                        {
                            name: "custom:code_gw",
                            errors: [`${translator.translate("This code is not available or doesn't exist")}`],
                        },
                    ]);
                }
                console.log(err);
                setLoading(false);
            } else {
                authenticate(values.email, values.password)
                    .then((data) => {
                        console.log(data);
                        setLoading(false);
                    })
                    .catch((err) => {
                        if (err.code === "UserNotConfirmedException") {
                            setLoading(false);
                            setEmail(values.email);
                            setPassword(values.password);
                            setConfirm(true);
                        }
                    });
            }
        });
    };

    return (
        <>
            {confirm ? (
                <Navigate to={"/confirm"} state={{ email: email, password: password }}></Navigate>
            ) : (
                <Form {...formItemLayout} form={form} name="register" onFinish={onFinish} scrollToFirstError>
                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                type: "email",
                                message: `${translator.translate("Please enter a valid email address")}`,
                            },
                            {
                                required: true,
                                message: `${translator.translate("Please enter a valid email address")}`,
                            },
                        ]}
                    >
                        <Input autoComplete="email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label={`${translator.translate("Password")}`}
                        rules={[
                            {
                                required: true,
                                message: `${translator.translate(
                                    "The password must contain at least eight characters, at least one \
upper and lower case letter, one number and one special character from [#?!@$%^&*-]"
                                )}`,
                                pattern: new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"),
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password autoComplete="new-password" />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        label={`${translator.translate("Confirmation")}`}
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: `${translator.translate("Please confirm your password")}`,
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(`${translator.translate("The passwords don't match")}`)
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password autoComplete="new-password" />
                    </Form.Item>
                    <Form.Item
                        name="given_name"
                        label={`${translator.translate("First name")}`}
                        rules={[
                            {
                                required: true,
                                message: `${translator.translate("The first name is required")}`,
                            },
                        ]}
                    >
                        <Input autoComplete="given-name" />
                    </Form.Item>
                    <Form.Item
                        name="family_name"
                        label={`${translator.translate("Last name")}`}
                        rules={[
                            {
                                required: true,
                                message: `${translator.translate("The last name is required")}`,
                            },
                        ]}
                    >
                        <Input autoComplete="family-name" />
                    </Form.Item>
                    <Form.Item
                        name="phone_number"
                        label={`${translator.translate("Phone number")}`}
                        rules={[
                            {
                                required: true,
                                validator: (_, value) =>
                                    isValidPhoneNumber(`+${value}`)
                                        ? Promise.resolve()
                                        : Promise.reject(
                                              new Error(translator.translate("Please enter a valid phone number"))
                                          ),
                            },
                        ]}
                    >
                        <PhoneInput
                            country={"fr"}
                            placeholder="Please enter your phone number"
                            enableSearch
                            disableSearchIcon
                            countryCodeEditable={false}
                            inputClass="input-phone-error"
                            buttonClass="input-phone-error"
                        />
                    </Form.Item>
                    <Form.Item
                        name="custom:boat_name"
                        label={`${translator.translate("Boat name")}`}
                        rules={[
                            {
                                required: true,
                                message: `${translator.translate("Please enter the name of your boat")}`,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="custom:code_gw"
                        label={`${translator.translate("Activation code")}`}
                        tooltip={`${translator.translate("Unique code assigned")}`}
                        rules={[
                            { required: true, whitespace: false, message: translator.translate("Please enter a code") },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value
                                        ? Promise.resolve()
                                        : Promise.reject(
                                              new Error(translator.translate("Please accept the conditions"))
                                          ),
                            },
                        ]}
                        {...tailFormItemLayout}
                    >
                        <Checkbox>
                            {translator.translate("I have read and accept the")}{" "}
                            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                {translator.translate("terms and conditions of use")}
                            </a>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button loading={loading} type="primary" htmlType="submit">
                            {translator.translate("Register my product")}
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </>
    );
};

export default SignUp;
