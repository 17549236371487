import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { TranslationsProvider } from "@eo-locale/react";
import en_locales from "./locale/en/translate.json";
import fr_locales from "./locale/fr/translate.json";
import reportWebVitals from "./reportWebVitals";
import { Account } from "./components/AccountContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Confirmation from "./components/Confirm";
import ForgotPassword from "./components/ForgotPassword";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { decideLanguage } from "./utils/LanguageService";

const languages = [en_locales, fr_locales];

const onTranslationError = (error: Error) => {
    console.error(error.message);
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <TranslationsProvider
            language={decideLanguage()}
            locales={languages}
            onError={onTranslationError}
        >        
            <Account>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<App />} />
                        <Route path="/confirm" element={<Confirmation />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    </Routes>
                </BrowserRouter>
            </Account>
        </TranslationsProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
