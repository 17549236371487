import { useState, useContext } from "react";
import { Button, Form, Input } from "antd";
import { AccountContext } from "./AccountContext";
import { Navigate } from "react-router-dom";
import "../styles/Signin.scss";
import { Link } from "react-router-dom";
import { useTranslator } from "@eo-locale/react";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 7,
        },
    },
};

const SignIn = () => {
    const translator = useTranslator();
    const [confirm, setConfirm] = useState<boolean>(false);
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [connected, setConnected] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [form] = Form.useForm();
    const { authenticate } = useContext(AccountContext);

    const onFinish = (values: any) => {
        setLoading(true);
        authenticate(values.email, values.password)
            .then((data) => {
                setConnected(true);
                setConfirm(true);
                setLoading(false);
            })
            .catch((err) => {
                if (err.code === "UserNotConfirmedException") {
                    setEmail(values.email);
                    setPassword(values.password)
                    setConfirm(true);
                }
                form.setFields([
                    {
                        name: "email",
                        errors: [translator.translate('Incorrect e-mail or password')],
                    },
                ]);
                setLoading(false);
            });
    };

    return (
        <>
            {confirm ? (
                <Navigate to={"/confirm"} state={{ email: email, resend: true, auth: connected, password: password }}></Navigate>
            ) : (
                <Form onFinish={onFinish} {...formItemLayout} form={form} name="signin" requiredMark={false}>
                    <Form.Item name="email" label="E-mail">
                        <Input autoComplete="email"/>
                    </Form.Item>
                    <Form.Item name="password" label={translator.translate('Password')}>
                        <Input.Password autoComplete="current-password" />
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button loading={loading} type="primary" htmlType="submit">
                            {translator.translate('Login')}
                        </Button>
                        <div className="forgot-password">
                            <Link to="/forgot-password">
                                <p>{translator.translate('Forgotten your password')} ?</p>
                            </Link>
                        </div>
                    </Form.Item>
                </Form>
            )}
        </>
    );
};

export default SignIn;
