import { useTranslator } from "@eo-locale/react";
import BluenavLogo from "../assets/logo-bluenav.png";
import "../styles/PrivacyPolicy.scss";

const PrivacyPolicy = () => {
    const translator = useTranslator();

    return (
        <div className="cognito-container">
            <div className="flex-container">
                <div className="policy-container">
                    <div className="logo-container">
                        <img src={BluenavLogo} alt="bluenav-logo"></img>
                    </div>
                    <div>
                        <p>{translator.translate('Your personal data')} :</p>
                        <p>{translator.translate('Your personal data is processed')}</p>
                        <p>{translator.translate('The legal basis for the processing is consent')} .</p>
                        <p>{translator.translate('You have the possibility')}</p>
                        <p>{translator.translate('If you refuse')}</p>
                        <p>{translator.translate('You can also')}</p>
                        <p>{translator.translate('You also have the right')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
