import { Button, Form, Input } from "antd";
import { useState, useContext } from "react";
import BluenavLogo from "../assets/logo-bluenav.png";
import "../styles/ForgotPassword.scss";
import { AccountContext } from "./AccountContext";
import { Link } from "react-router-dom";
import { useTranslator } from "@eo-locale/react";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 5,
        },
    },
};

const ForgotPassword = () => {
    const translator = useTranslator();
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [stage, setStage] = useState<number>(1);

    const { register } = useContext(AccountContext);
    const [form] = Form.useForm();
    const [formReset] = Form.useForm();

    const onFinish = (values: any) => {
        setLoading(true);
        setEmail(values.email);

        let user = register(values.email);

        user.forgotPassword({
            onSuccess: (data) => {
                console.log("onSuccess:", data);
            },
            onFailure: (err) => {
                console.error("onFailure:", err);
            },
            inputVerificationCode: (data) => {
                console.log("Input code:", data);
                setLoading(false);
                setStage(2);
            },
        });
    };

    const onReset = (values: any) => {
        setLoading(true);

        let user = register(email);

        user.confirmPassword(values.code, values.password, {
            onSuccess: (data) => {
                setLoading(false);
                setStage(3);
            },
            onFailure: (err) => {
                formReset.setFields([
                    {
                        name: "code",
                        errors: ["Le code de vérification est invalide"],
                    },
                ]);

                console.error("onFailure:", err);
                setLoading(false);
            },
        });
    };

    return (
        <>
            <div className="forgot-password-container">
                <div className="forgot-form-container">
                    <div className="goback">
                        <Link to={"/"}>{translator.translate('Back')}</Link>
                    </div>
                    <div className="logo-container">
                        <img src={BluenavLogo} alt="bluenav-logo"></img>
                    </div>
                    {stage === 2 ? (
                        <>
                            <div>
                                <p>
                                    {translator.translate('If the email address entered exists')}
                                </p>
                            </div>
                            <Form
                                layout={"vertical"}
                                onFinish={onReset}
                                form={formReset}
                                name="reset"
                                requiredMark={false}
                            >
                                <Form.Item
                                    name="code"
                                    label={`${translator.translate('Verification code')}`}
                                    rules={[{ 
                                        required: true, 
                                        whitespace: false, 
                                        message: `${translator.translate('Please enter a code')}`
                                    },]}
                                >
                                    <Input autoComplete="one-time-code" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label={`${translator.translate('Password')}`}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                            `${translator.translate('The password must contain at least eight characters, \
at least one upper and lower case letter, one number and one special character from [#?!@$%^&*-]')}`,
                                            pattern: new RegExp(
                                                "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                                            ),
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password autoComplete="new-password" />
                                </Form.Item>
                                <Form.Item
                                    name="confirm"
                                    label={`${translator.translate('Password confirmation')}`}
                                    dependencies={["password"]}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: `${translator.translate('Please confirm your password')}`,
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("password") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(`${translator.translate('The passwords don\'t match')}`)
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password autoComplete="new-password" />
                                </Form.Item>
                                <Form.Item>
                                    <Button loading={loading} type="primary" htmlType="submit">
                                        {translator.translate('Reset password')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </>
                    ) : stage === 1 ? (
                        <>
                            <div>
                                <p>{translator.translate('A verification code will be sent to you by email to validate your \
identity')}</p>
                            </div>
                            <Form
                                layout={"vertical"}
                                onFinish={onFinish}
                                form={form}
                                name="send-code"
                                requiredMark={false}
                            >
                                <Form.Item
                                    name="email"
                                    label="E-mail"
                                    rules={[
                                        {
                                            type: "email",
                                            message: `${translator.translate('Email address not valid')}`,
                                        },
                                        {
                                            required: true,
                                            message: `${translator.translate('Email address not valid')}`,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item>
                                    <Button loading={loading} type="primary" htmlType="submit">
                                        {translator.translate('Send a code')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </>
                    ) : (
                        <>
                            <div>
                                <p>{translator.translate('Password successfully reset')}</p>
                            </div>
                            <div>
                                <p>
                                    <a href="/">{translator.translate('Return to the login page')}</a>
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
