import { createContext } from "react";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "../utils/userPool";

const register: (username: string) => CognitoUser = (username: string) => {
    const user = new CognitoUser({ Username: username, Pool: UserPool });
    return user;
};

const getSession = async () =>
    await new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
            user.getSession(async (err: any, session: any) => {
                if (err) {
                    reject();
                } else {
                    const attributes = await new Promise((resolve, reject) => {
                        user.getUserAttributes((err, attributes: any) => {
                            if (err) {
                                reject(err);
                            } else {
                                const results = {};

                                for (let attribute of attributes) {
                                    const { Name, Value } = attribute;
                                    // @ts-ignore
                                    results[Name] = Value; 
                                }

                                resolve(results);
                            }
                        });
                    });

                    resolve({
                        user,
                        ...session,
                        // @ts-ignore
                        ...attributes, 
                    });
                }
            });
        } else {
            reject();
        }
    });

const authenticate = async (username: string, password: string) =>
    await new Promise((resolve, reject) => {
        const user = new CognitoUser({ Username: username, Pool: UserPool });
        const authDetails = new AuthenticationDetails({ Username: username, Password: password });

        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                console.log("onSuccess:", data);
                resolve(data);
            },

            onFailure: (err) => {
                console.error("onFailure:", err);
                reject(err);
            },

            newPasswordRequired: (data) => {
                console.log("newPasswordRequired:", data);
                resolve(data);
            },
        });
    });

const AccountContext = createContext({ register, authenticate, getSession });

const Account = (props: any) => {
    return <AccountContext.Provider value={{ register, authenticate, getSession }}>{props.children}</AccountContext.Provider>;
};

export { Account, AccountContext };
